/**
 * This is an AUTOGENERATED FILE generated by shared/platform_subtype_generation.py.
 * DO NOT modify this file manually. To update this file,
 * modify shared/platform_subtypes.json5 and run shared/platform_subtype_generation.py.
 */

import { PlatformSubtypeMetadata } from '@/utils/reports/platform_subtype_utils'
import { ProductType, Archetype, Platform, PlatformSubtype, PlatformSubtypeId } from './enums'


export const PRODUCT_TO_DEFAULT_SUBTYPE: Readonly<Partial<Record<ProductType, PlatformSubtype>>> = {
  [ProductType.DOMAIN]: PlatformSubtype.DOMAINS,
  [ProductType.SOCIAL_MEDIA]: PlatformSubtype.SOCIAL_MEDIA_OTHER,
  [ProductType.CRYPTO]: PlatformSubtype.CRYPTO_OTHER,
  [ProductType.MOBILE_APP]: PlatformSubtype.MOBILE_APPS_OTHER,
  [ProductType.PAID_AD]: PlatformSubtype.PAID_ADS_OTHER,
  [ProductType.ECOMMERCE]: PlatformSubtype.ECOMMERCE_OTHER,
  [ProductType.EMAIL]: PlatformSubtype.EMAIL,
  [ProductType.DARKWEB]: PlatformSubtype.DARKWEB,
  [ProductType.DARK_MARKET]: PlatformSubtype.DARKMARKET_OTHER,
  [ProductType.TELCO]: PlatformSubtype.TELCO,
  [ProductType.SUSPICIOUS_EMAILS]: PlatformSubtype.SUSPICIOUS_EMAILS,
}


export const PLATFORM_SUBTYPE_METADATA_LIST: Partial<Record<PlatformSubtypeId, PlatformSubtypeMetadata>> = {
  [PlatformSubtypeId.COINBASE]: {
    id: PlatformSubtypeId.COINBASE,
    name: PlatformSubtype.COINBASE,
    platform: Platform.COINBASE,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.BINANCE]: {
    id: PlatformSubtypeId.BINANCE,
    name: PlatformSubtype.BINANCE,
    platform: Platform.BINANCE,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.OPENSEA]: {
    id: PlatformSubtypeId.OPENSEA,
    name: PlatformSubtype.OPENSEA,
    platform: Platform.OPENSEA,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.MINTABLE]: {
    id: PlatformSubtypeId.MINTABLE,
    name: PlatformSubtype.MINTABLE,
    platform: Platform.MINTABLE,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.MAGICEDEN]: {
    id: PlatformSubtypeId.MAGICEDEN,
    name: PlatformSubtype.MAGICEDEN,
    platform: Platform.MAGICEDEN,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.OKX]: {
    id: PlatformSubtypeId.OKX,
    name: PlatformSubtype.OKX,
    platform: Platform.OKX,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.RARIBLE]: {
    id: PlatformSubtypeId.RARIBLE,
    name: PlatformSubtype.RARIBLE,
    platform: Platform.RARIBLE,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.CRYPTO_OTHER]: {
    id: PlatformSubtypeId.CRYPTO_OTHER,
    name: PlatformSubtype.CRYPTO_OTHER,
    platform: Platform.CRYPTO_OTHER,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.SHELLIX]: {
    id: PlatformSubtypeId.SHELLIX,
    name: PlatformSubtype.SHELLIX,
    platform: Platform.SHELLIX,
    archetype: Archetype.DARK_MARKET,
    product: ProductType.DARK_MARKET,
  },
  [PlatformSubtypeId.DARKMARKET_OTHER]: {
    id: PlatformSubtypeId.DARKMARKET_OTHER,
    name: PlatformSubtype.DARKMARKET_OTHER,
    platform: Platform.DARKMARKET_OTHER,
    archetype: Archetype.DARK_MARKET,
    product: ProductType.DARK_MARKET,
  },
  [PlatformSubtypeId.DARKWEB]: {
    id: PlatformSubtypeId.DARKWEB,
    name: PlatformSubtype.DARKWEB,
    platform: Platform.DARKWEB,
    archetype: Archetype.DARKWEB,
    product: ProductType.DARKWEB,
  },
  [PlatformSubtypeId.CRED_LEAKS]: {
    id: PlatformSubtypeId.CRED_LEAKS,
    name: PlatformSubtype.CRED_LEAKS,
    platform: Platform.CRED_LEAKS,
    archetype: Archetype.CRED_LEAKS,
    product: ProductType.DARKWEB,
  },
  [PlatformSubtypeId.CREDIT_CARD_LEAKS]: {
    id: PlatformSubtypeId.CREDIT_CARD_LEAKS,
    name: PlatformSubtype.CREDIT_CARD_LEAKS,
    platform: Platform.CREDIT_CARD_LEAKS,
    archetype: Archetype.CREDIT_CARD_LEAKS,
    product: ProductType.DARKWEB,
  },
  [PlatformSubtypeId.API_LEAKS]: {
    id: PlatformSubtypeId.API_LEAKS,
    name: PlatformSubtype.API_LEAKS,
    platform: Platform.API_LEAKS,
    archetype: Archetype.API_LEAKS,
    product: ProductType.DARKWEB,
  },
  [PlatformSubtypeId.DOMAINS]: {
    id: PlatformSubtypeId.DOMAINS,
    name: PlatformSubtype.DOMAINS,
    platform: Platform.DOMAINS,
    archetype: Archetype.DOMAIN,
    product: ProductType.DOMAIN,
  },
  [PlatformSubtypeId.GITBOOK]: {
    id: PlatformSubtypeId.GITBOOK,
    name: PlatformSubtype.GITBOOK,
    platform: Platform.GITBOOK,
    archetype: Archetype.DOMAIN,
    product: ProductType.DOMAIN,
  },
  [PlatformSubtypeId.WEBFLOW]: {
    id: PlatformSubtypeId.WEBFLOW,
    name: PlatformSubtype.WEBFLOW,
    platform: Platform.WEBFLOW,
    archetype: Archetype.DOMAIN,
    product: ProductType.DOMAIN,
  },
  [PlatformSubtypeId.SHOPEE]: {
    id: PlatformSubtypeId.SHOPEE,
    name: PlatformSubtype.SHOPEE,
    platform: Platform.SHOPEE,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.ROBLOX]: {
    id: PlatformSubtypeId.ROBLOX,
    name: PlatformSubtype.ROBLOX,
    platform: Platform.ROBLOX,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.AMAZON]: {
    id: PlatformSubtypeId.AMAZON,
    name: PlatformSubtype.AMAZON,
    platform: Platform.AMAZON,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.WALMART]: {
    id: PlatformSubtypeId.WALMART,
    name: PlatformSubtype.WALMART,
    platform: Platform.WALMART,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.ETSY]: {
    id: PlatformSubtypeId.ETSY,
    name: PlatformSubtype.ETSY,
    platform: Platform.ETSY,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.EBAY]: {
    id: PlatformSubtypeId.EBAY,
    name: PlatformSubtype.EBAY,
    platform: Platform.EBAY,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.LAZADA]: {
    id: PlatformSubtypeId.LAZADA,
    name: PlatformSubtype.LAZADA,
    platform: Platform.LAZADA,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.Z2U]: {
    id: PlatformSubtypeId.Z2U,
    name: PlatformSubtype.Z2U,
    platform: Platform.Z2U,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.TOKOPEDIA]: {
    id: PlatformSubtypeId.TOKOPEDIA,
    name: PlatformSubtype.TOKOPEDIA,
    platform: Platform.TOKOPEDIA,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.FACEBOOK_MARKETPLACE]: {
    id: PlatformSubtypeId.FACEBOOK_MARKETPLACE,
    name: PlatformSubtype.FACEBOOK_MARKETPLACE,
    platform: Platform.FACEBOOK_MARKETPLACE,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.ECOMMERCE_OTHER]: {
    id: PlatformSubtypeId.ECOMMERCE_OTHER,
    name: PlatformSubtype.ECOMMERCE_OTHER,
    platform: Platform.ECOMMERCE_OTHER,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.EMAIL]: {
    id: PlatformSubtypeId.EMAIL,
    name: PlatformSubtype.EMAIL,
    platform: Platform.EMAIL,
    archetype: Archetype.EMAIL,
    product: ProductType.EMAIL,
  },
  [PlatformSubtypeId.APPLE_APP_STORE]: {
    id: PlatformSubtypeId.APPLE_APP_STORE,
    name: PlatformSubtype.APPLE_APP_STORE,
    platform: Platform.APPLE_APP_STORE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKGK]: {
    id: PlatformSubtypeId.APKGK,
    name: PlatformSubtype.APKGK,
    platform: Platform.APKGK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.UPTODOWN]: {
    id: PlatformSubtypeId.UPTODOWN,
    name: PlatformSubtype.UPTODOWN,
    platform: Platform.UPTODOWN,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.STEPRIMO]: {
    id: PlatformSubtypeId.STEPRIMO,
    name: PlatformSubtype.STEPRIMO,
    platform: Platform.STEPRIMO,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APPBRAIN]: {
    id: PlatformSubtypeId.APPBRAIN,
    name: PlatformSubtype.APPBRAIN,
    platform: Platform.APPBRAIN,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.GOOGLE_PLAY_STORE]: {
    id: PlatformSubtypeId.GOOGLE_PLAY_STORE,
    name: PlatformSubtype.GOOGLE_PLAY_STORE,
    platform: Platform.GOOGLE_PLAY_STORE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APTOIDE]: {
    id: PlatformSubtypeId.APTOIDE,
    name: PlatformSubtype.APTOIDE,
    platform: Platform.APTOIDE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.NINEAPPS]: {
    id: PlatformSubtypeId.NINEAPPS,
    name: PlatformSubtype.NINEAPPS,
    platform: Platform.NINEAPPS,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKCOMBO]: {
    id: PlatformSubtypeId.APKCOMBO,
    name: PlatformSubtype.APKCOMBO,
    platform: Platform.APKCOMBO,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKPURE]: {
    id: PlatformSubtypeId.APKPURE,
    name: PlatformSubtype.APKPURE,
    platform: Platform.APKPURE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.MOBILE_APPS_OTHER]: {
    id: PlatformSubtypeId.MOBILE_APPS_OTHER,
    name: PlatformSubtype.MOBILE_APPS_OTHER,
    platform: Platform.MOBILE_APPS_OTHER,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.GOOGLE_ADS]: {
    id: PlatformSubtypeId.GOOGLE_ADS,
    name: PlatformSubtype.GOOGLE_ADS,
    platform: Platform.GOOGLE_ADS,
    archetype: Archetype.PAID_AD,
    product: ProductType.PAID_AD,
  },
  [PlatformSubtypeId.FACEBOOK_ADS]: {
    id: PlatformSubtypeId.FACEBOOK_ADS,
    name: PlatformSubtype.FACEBOOK_ADS,
    platform: Platform.FACEBOOK_ADS,
    archetype: Archetype.PAID_AD,
    product: ProductType.PAID_AD,
  },
  [PlatformSubtypeId.BING_ADS]: {
    id: PlatformSubtypeId.BING_ADS,
    name: PlatformSubtype.BING_ADS,
    platform: Platform.BING_ADS,
    archetype: Archetype.PAID_AD,
    product: ProductType.PAID_AD,
  },
  [PlatformSubtypeId.PAID_ADS_OTHER]: {
    id: PlatformSubtypeId.PAID_ADS_OTHER,
    name: PlatformSubtype.PAID_ADS_OTHER,
    platform: Platform.PAID_ADS_OTHER,
    archetype: Archetype.PAID_AD,
    product: ProductType.PAID_AD,
  },
  [PlatformSubtypeId.TWITTER]: {
    id: PlatformSubtypeId.TWITTER,
    name: PlatformSubtype.TWITTER,
    platform: Platform.TWITTER,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TWITTER_POST]: {
    id: PlatformSubtypeId.TWITTER_POST,
    name: PlatformSubtype.TWITTER_POST,
    platform: Platform.TWITTER,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TIKTOK]: {
    id: PlatformSubtypeId.TIKTOK,
    name: PlatformSubtype.TIKTOK,
    platform: Platform.TIKTOK,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TIKTOK_VIDEO]: {
    id: PlatformSubtypeId.TIKTOK_VIDEO,
    name: PlatformSubtype.TIKTOK_VIDEO,
    platform: Platform.TIKTOK,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.FACEBOOK]: {
    id: PlatformSubtypeId.FACEBOOK,
    name: PlatformSubtype.FACEBOOK,
    platform: Platform.FACEBOOK,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.FACEBOOK_POST]: {
    id: PlatformSubtypeId.FACEBOOK_POST,
    name: PlatformSubtype.FACEBOOK_POST,
    platform: Platform.FACEBOOK,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.FACEBOOK_GROUPS]: {
    id: PlatformSubtypeId.FACEBOOK_GROUPS,
    name: PlatformSubtype.FACEBOOK_GROUPS,
    platform: Platform.FACEBOOK,
    archetype: Archetype.SOCIAL_MEDIA_GROUP,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.FACEBOOK_PAGE]: {
    id: PlatformSubtypeId.FACEBOOK_PAGE,
    name: PlatformSubtype.FACEBOOK_PAGE,
    platform: Platform.FACEBOOK,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.INSTAGRAM]: {
    id: PlatformSubtypeId.INSTAGRAM,
    name: PlatformSubtype.INSTAGRAM,
    platform: Platform.INSTAGRAM,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.INSTAGRAM_POST]: {
    id: PlatformSubtypeId.INSTAGRAM_POST,
    name: PlatformSubtype.INSTAGRAM_POST,
    platform: Platform.INSTAGRAM,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.YOUTUBE]: {
    id: PlatformSubtypeId.YOUTUBE,
    name: PlatformSubtype.YOUTUBE,
    platform: Platform.YOUTUBE,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TELEGRAM]: {
    id: PlatformSubtypeId.TELEGRAM,
    name: PlatformSubtype.TELEGRAM,
    platform: Platform.TELEGRAM,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TELEGRAM_POST]: {
    id: PlatformSubtypeId.TELEGRAM_POST,
    name: PlatformSubtype.TELEGRAM_POST,
    platform: Platform.TELEGRAM,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.LINKEDIN]: {
    id: PlatformSubtypeId.LINKEDIN,
    name: PlatformSubtype.LINKEDIN,
    platform: Platform.LINKEDIN,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.REDDIT]: {
    id: PlatformSubtypeId.REDDIT,
    name: PlatformSubtype.REDDIT,
    platform: Platform.REDDIT,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.GITHUB]: {
    id: PlatformSubtypeId.GITHUB,
    name: PlatformSubtype.GITHUB,
    platform: Platform.GITHUB,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.PINTEREST]: {
    id: PlatformSubtypeId.PINTEREST,
    name: PlatformSubtype.PINTEREST,
    platform: Platform.PINTEREST,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.DISCORD]: {
    id: PlatformSubtypeId.DISCORD,
    name: PlatformSubtype.DISCORD,
    platform: Platform.DISCORD,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.WHATSAPP]: {
    id: PlatformSubtypeId.WHATSAPP,
    name: PlatformSubtype.WHATSAPP,
    platform: Platform.WHATSAPP,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TRUTH_SOCIAL]: {
    id: PlatformSubtypeId.TRUTH_SOCIAL,
    name: PlatformSubtype.TRUTH_SOCIAL,
    platform: Platform.TRUTH_SOCIAL,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.FACEBOOK_PROFILE]: {
    id: PlatformSubtypeId.FACEBOOK_PROFILE,
    name: PlatformSubtype.FACEBOOK_PROFILE,
    platform: Platform.FACEBOOK_PROFILE,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.SPOTIFY]: {
    id: PlatformSubtypeId.SPOTIFY,
    name: PlatformSubtype.SPOTIFY,
    platform: Platform.SPOTIFY,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TWITCH]: {
    id: PlatformSubtypeId.TWITCH,
    name: PlatformSubtype.TWITCH,
    platform: Platform.TWITCH,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.THREADS]: {
    id: PlatformSubtypeId.THREADS,
    name: PlatformSubtype.THREADS,
    platform: Platform.THREADS,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.SNAPCHAT]: {
    id: PlatformSubtypeId.SNAPCHAT,
    name: PlatformSubtype.SNAPCHAT,
    platform: Platform.SNAPCHAT,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.WEIBO]: {
    id: PlatformSubtypeId.WEIBO,
    name: PlatformSubtype.WEIBO,
    platform: Platform.WEIBO,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.MEDIUM]: {
    id: PlatformSubtypeId.MEDIUM,
    name: PlatformSubtype.MEDIUM,
    platform: Platform.MEDIUM,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.LINKTREE]: {
    id: PlatformSubtypeId.LINKTREE,
    name: PlatformSubtype.LINKTREE,
    platform: Platform.LINKTREE,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TUMBLR]: {
    id: PlatformSubtypeId.TUMBLR,
    name: PlatformSubtype.TUMBLR,
    platform: Platform.TUMBLR,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.SOCIAL_MEDIA_OTHER]: {
    id: PlatformSubtypeId.SOCIAL_MEDIA_OTHER,
    name: PlatformSubtype.SOCIAL_MEDIA_OTHER,
    platform: Platform.SOCIAL_MEDIA_OTHER,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TELCO]: {
    id: PlatformSubtypeId.TELCO,
    name: PlatformSubtype.TELCO,
    platform: Platform.TELCO,
    archetype: Archetype.TELCO,
    product: ProductType.TELCO,
  },
  [PlatformSubtypeId.SUSPICIOUS_EMAILS]: {
    id: PlatformSubtypeId.SUSPICIOUS_EMAILS,
    name: PlatformSubtype.SUSPICIOUS_EMAILS,
    platform: Platform.SUSPICIOUS_EMAILS,
    archetype: Archetype.SUSPICIOUS_EMAILS,
    product: ProductType.SUSPICIOUS_EMAILS,
  },
  [PlatformSubtypeId.CLOUDFLARE_PAGES]: {
    id: PlatformSubtypeId.CLOUDFLARE_PAGES,
    name: PlatformSubtype.CLOUDFLARE_PAGES,
    platform: Platform.CLOUDFLARE_PAGES,
    archetype: Archetype.DOMAIN,
    product: ProductType.DOMAIN,
  },
}
