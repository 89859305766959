import DoppelLink from '@/components/report_detail/doppel_link'
import TablePreview, { TablePreviewSize } from '@/components/tables/table_preview'
import { DetectionAssetType } from '@/generated/enums'
import { Text } from '@chakra-ui/react'

export function DetectionAssetAsset({ detectionAsset, should_render_images = false }) {
  switch (detectionAsset.type) {
    case DetectionAssetType.REGEX_SITE_TEXT:
    case DetectionAssetType.REGEX_URL:
    case DetectionAssetType.REGEX_HTML:
      return <Text>{detectionAsset.value}</Text>

    case DetectionAssetType.SITE_FAVICON:
      if (should_render_images) {
        const faviconUrl = detectionAsset.value
        return (
          <a href={faviconUrl} rel="noreferrer" target="_blank">
            <TablePreview
              previewSize={TablePreviewSize.LARGE}
              shouldSign
              src={faviconUrl}
            />
          </a>
        )
      } else {
        return <DoppelLink href={detectionAsset.value} name="Link" />
      }
    case DetectionAssetType.SITE_SCREENSHOT:
    case DetectionAssetType.FULL_SITE_SCREENSHOT:
    case DetectionAssetType.MOBILE_SITE_SCREENSHOT:
    case DetectionAssetType.MOBILE_FULL_SITE_SCREENSHOT:
    case DetectionAssetType.BRAND_LOGO:
    case DetectionAssetType.SOCIAL_MEDIA_USER_PROFILE_IMAGE:
      if (should_render_images) {
        return (
          <a href={detectionAsset.value} rel="noreferrer" target="_blank">
            <TablePreview
              previewSize={TablePreviewSize.LARGE}
              src={detectionAsset.value}
            />
          </a>
        )
      } else {
        return <DoppelLink href={detectionAsset.value} name="Link" />
      }

    case DetectionAssetType.SITE_HTML:
    case DetectionAssetType.SITE_TEXT:
    case DetectionAssetType.MOBILE_SITE_HTML:
    case DetectionAssetType.MOBILE_SITE_TEXT:
      return <DoppelLink href={detectionAsset.value} name="Link" />

    default:
      return <Text>{detectionAsset.value}</Text>
  }
}
