import {
  Archetype,
  Platform,
  PlatformSubtype,
  PlatformSubtypeId,
  ProductType,
} from '@/generated/enums'
import { ReportType } from '../constants'
import { formatSnakeStringWithCapitalization } from '../string_utils'
import { PLATFORM_TYPE_DISPLAY_MAP, REPORT_TYPES_WITH_PLATFORMS } from './report_utils'
import {
  PLATFORM_SUBTYPE_METADATA_LIST,
  PRODUCT_TO_DEFAULT_SUBTYPE,
} from '@/generated/platform_subtype_mapping'

export type PlatformSubtypeMetadata = {
  id: PlatformSubtypeId
  name: PlatformSubtype
  platform: Platform
  archetype: Archetype
  product: ProductType
}

export const PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE = 'Other'

export const getPlatformSubtypeDisplayValue = (
  subtype_name: string,
  reportType?: ReportType | undefined,
): string => {
  if (!subtype_name) return ''
  let displayAsOther = false
  if (reportType && REPORT_TYPES_WITH_PLATFORMS.includes(reportType)) {
    const product = PLATFORM_TYPE_DISPLAY_MAP[reportType] // convert between enums
    if (product && subtype_name == PRODUCT_TO_DEFAULT_SUBTYPE[product]) {
      displayAsOther = true
    }
  }
  return displayAsOther
    ? PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE
    : formatSnakeStringWithCapitalization(subtype_name)
}

export const isSubtypeDefault = (subtype: PlatformSubtypeMetadata): boolean => {
  return PRODUCT_TO_DEFAULT_SUBTYPE[subtype.product] === subtype.name
}

/**
 * constant but platform subtype edits will reflect automatically (when codegen data changes)
 */
export const PRODUCTS_WITH_MULTIPLE_SUBTYPES: ProductType[] = (() => {
  const subtypeCounts: Partial<Record<ProductType, number>> = {}
  for (const { product } of Object.values(PLATFORM_SUBTYPE_METADATA_LIST)) {
    subtypeCounts[product] = (subtypeCounts[product] || 0) + 1
  }
  const products = Object.keys(subtypeCounts) as ProductType[]
  return products.filter((product) => subtypeCounts[product] > 1)
})()

/**
 * for placing generic subtypes at the top (e.g. social_media_other)
 */
export const PLATFORM_SUBTYPE_SORTED_DROPDOWN_OPTIONS: PlatformSubtypeMetadata[] =
  (() => {
    const platformSubtypes = Object.values(PLATFORM_SUBTYPE_METADATA_LIST)

    // to display at top of list
    // order is currently arbitrary, explicitly define if needed
    const productGenericSubtypeNames: string[] = Object.values(
      PRODUCT_TO_DEFAULT_SUBTYPE,
    )

    // preserve order that they're defined in mapping
    const productGenericSubtypes = productGenericSubtypeNames
      .map((name) => platformSubtypes.find((subtype) => subtype.name === name))
      .filter((subtype) => !!subtype)

    const nonProductGenericSubtypes = platformSubtypes
      .filter((subtype) => !productGenericSubtypeNames.includes(subtype.name))
      .sort((a, b) => {
        const aProduct = a.product.toLowerCase()
        const bProduct = b.product.toLowerCase()
        if (aProduct !== bProduct) {
          return aProduct.localeCompare(bProduct)
        }
        return a.name.localeCompare(b.name)
      })

    return [...productGenericSubtypes, ...nonProductGenericSubtypes]
  })()
